html {
  scroll-behavior: smooth;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Biotif, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
}

.week_hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #c1d9df;
  background-image: url('../images/cover__image_comp.png');
  background-position: 50% 39%;
  background-size: cover;
}

.week__container {
  width: 100%;
  max-width: 1120px;
}

.week__container.is--footer {
  max-width: 1120px;
}

.week__container.is--nav {
  max-width: 95%;
}

.week__container.container-small {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.hero__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 75%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading_h1 {
  position: relative;
  margin-top: 0px;
  color: #000;
}

.heading_h1.is--middle {
  padding-right: 40px;
  padding-left: 40px;
  color: #fff;
  font-size: 3.4rem;
  line-height: 3.5rem;
  text-align: center;
  text-shadow: 3px 0 8px rgba(0, 0, 0, 0.52);
}

.hero__overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: #312b72;
  opacity: 0.46;
}

.hero__overlay.is--dark {
  background-color: #04002d;
}

.hero__searchbox-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 40px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.hero__searchbox-container.mt-0 {
  margin-top: 0px;
}

.hero__searchbox-container.mt-0.is--agency {
  border-bottom: 1px solid #e2e2e2;
}

.hero__searchform {
  width: 100%;
  margin-bottom: 0px;
}

.hero__searchform__inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hero__searchform__inner.is--30 {
  width: 30%;
}

.hero__searchform__inner.is--agenct {
  padding-right: 20px;
  padding-left: 20px;
}

.hero__searchform_inputwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 85px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.hero__searchform-label {
  margin-bottom: 0px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
}

.hero__searchbox-select {
  margin-bottom: 0px;
  margin-left: -4px;
  padding: 0px;
  border: 1px none #000;
  background-color: #fff;
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
}

.hero__searchbox-select.is--agency {
  position: relative;
  height: 50px;
  padding-right: 20px;
  padding-left: 10px;
  border-style: solid;
  border-color: #c8c8c8;
  color: #676767;
  font-weight: 400;
}

.hero__searchbox-submit {
  height: 85px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #2eb1ff;
  font-size: 16px;
  font-weight: 500;
}

.weekala__section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 115px;
  padding-bottom: 115px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.weekala__section.is--dark {
  background-color: #1a1a1a;
}

.weekala__section.padding-top-0 {
  padding-top: 0px;
}

.weekala__section.is--gray {
  background-color: #f2f6f9;
}

.weekala__section.ia--agency {
  /* padding-top: 250px; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.heading_h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 2.4rem;
  line-height: 3rem;
}

.heading_h2.is--middle {
  text-align: center;
}

.heading_h2.is--middle.max--width70 {
  max-width: 65%;
  margin-right: auto;
  margin-left: auto;
  font-size: 2.2rem;
  line-height: 2.5rem;
}

.heading_h2.is--middle.is--auto {
  margin-right: auto;
  margin-left: auto;
}

.heading_h2.is--sectiontitle {
  font-weight: 600;
  text-align: center;
}

.heading_h2.is--sectiontitle.is--white {
  color: #fff;
}

.heading_h2.mb-20 {
  margin-bottom: 10px;
  font-weight: 600;
}

.heading_h2.is--blogheader {
  max-width: 50%;
}

.heading_h2.singlepost--title {
  margin-bottom: 10px;
  font-weight: 600;
  text-align: center;
}

.heading_h2.singlepost--title.is--white {
  color: #fff;
}

.weekala__services {
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.weekala__servicecard {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #bababa;
  -o-object-fit: fill;
  object-fit: fill;
}

.weekala__servicecard.is--digitalagency {
  height: 380px;
  padding: 20px;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}

.weekala__servicecard.is--designagency {
  height: 380px;
  padding: 20px;
  background-image: url('../images/design-agency.png');
  background-position: 0px 0px;
  background-size: cover;
}

.weekala__servicecard.is--advert {
  height: 380px;
  padding: 20px;
  background-image: url('../images/advert_agency.png');
  background-position: 0% 50%;
  background-size: cover;
}

.heading_h3 {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.heading_h3.is--servicebox {
  margin-top: 0px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
}

.heading_h3.is--black {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.heading_h3.is--black.is--blocktitle {
  line-height: 1.7rem;
  text-align: left;
}

.heading_h3.sa--block-title {
  font-size: 1.3rem;
  font-weight: 500;
}

.heading_h3.sa--block-title.mt-30 {
  margin-top: 30px;
}

.section__titlecontainer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 65%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section__titlecontainer.is--left {
  margin-left: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.featured__grid {
  position: relative;
  margin-top: 40px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 41px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

@media screen and (max-width: 1024px) {
  .featured__grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1440px) {
  .featured__grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.featured__grid-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.featured__grid-box.is--1 {
  position: relative;
  margin-top: 60px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.featured__grid-box.is--1.is--agencyresult {
  width: 22%;
  box-shadow: 1px 1px 12px 0 #e2e2e2;
}

.featured__grid-box.is--1.is--agencyresult.is--shortlisted {
  width: 31%;
}

.featured__grid-box.is--2 {
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.featured__grid-box.is--3 {
  position: relative;
  margin-top: 60px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.featured__grid-box.is--4 {
  position: relative;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.featured__logo-img {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  height: 200px;
  width: 200px;
}

.featured__grid-contentbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured__grid-catwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured__grid-cattitle {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  color: #2693f2;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
  text-align: center;
}

.featured__grid-title {
  position: relative;
  margin-top: 20px;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
}

.featured__grid-place {
  position: relative;
  margin-top: 5px;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  text-align: center;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
}

.featured__grid-place.is--review {
  margin-top: 10px;
  color: #818181;
  font-size: 0.8rem;
}

.featured__grid-reviewbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.featured__grid-review {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #2db1ff;
}

.featured__grid-reviewtitle {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.section__title-description {
  position: relative;
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 1.6rem;
  text-align: center;
}

.section__title-description.is--white {
  color: #dbdbdb;
}

.section__title-description.is--white.is--left {
  text-align: left;
}

.cta__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta__primary {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #2db1ff;
  background-color: #2db1ff;
  font-size: 0.9rem;
  line-height: 1.4rem;
  font-weight: 500;
}

.cta__primary.is--outline {
  border-style: solid;
  border-width: 1px;
  border-color: #2db1ff;
  background-color: transparent;
}

.cta__primary.is--outline.is--white {
  padding: 16px 28px;
  border-color: #fff;
}

.cta__primary.is--outline.is--primary {
  color: #2db1ff;
}

.cta__primary.is--outline.is--primary.no-margin {
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px;
}

.cta__primary.is--outline.is--primary.is--full.mt-20 {
  margin-top: 20px;
  background-color: transparent;
}

.cta__primary.is--footer {
  margin-top: 20px;
  margin-left: 0px;
}

.cta__primary.is--navigation {
  padding-top: 15px;
  padding-bottom: 15px;
}

.cta__primary.is--navigation.mt-20 {
  margin-top: 20px;
}

.cta__primary.is--submit {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.cta__primary.is--navigation-copy {
  padding-top: 15px;
  padding-bottom: 15px;
}

.cta__primary.is--full {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #2db1ff;
  text-align: center;
}

.cta__primary.shortlist {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.cta__primary.shortlist.mt-20 {
  margin-top: 20px;
}

.howitworks__grid {
  max-width: 90%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 71px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.howitworks__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.howitworks__img {
  position: relative;
  width: auto;
  margin-bottom: 20px;
}

.blog__grid {
  position: relative;
  max-width: 100%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 73px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.blog__grid.is--bloglist {
  margin-top: 0px;
  grid-row-gap: 60px;
}

.blog__box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blog__box--imgwrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blog__grid--image {
  width: 100%;
  height: 380px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog__grid--image.is--bloglist {
  width: 100%;
  height: 380px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.footer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #242d3c;
  background-image: url('../images/footer.png');
  background-position: 50% 100%;
  background-size: 127%;
  background-repeat: no-repeat;
}

.footer__grid {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer__content--title {
  color: #fff;
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: 600;
}

.footer__content-description {
  position: relative;
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 1.6rem;
  text-align: center;
}

.footer__content-description.is--white {
  color: #dbdbdb;
  text-align: left;
}

.footer__menubox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer__menulinks-container {
  width: 100%;
}

.footer__menulist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer__menublock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__menulink {
  position: relative;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  color: #e8e8e8;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}

.copyright__text {
  position: relative;
  margin-top: 40px;
  color: #d2d2d2;
}

.navigaiton__header {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #00000091;
}

.navigaiton__header.is--dropshadow {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 3px 0 #ccc;
}

.navigaiton__header.is--dropshadow.is--relatice {
  position: relative;
  overflow: hidden;
}

.navigaiton__header.is--dropshadow.is--relatice.isnav {
  overflow: visible;
}

.navigation__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__left {
  position: relative;
  width: 200px;
}

.navgiation__cta-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta__white {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  padding: 16px 29px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: #fff;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.4rem;
  font-weight: 500;
}

.cta__white.is--outline {
  border-style: solid;
  border-width: 1px;
  border-color: #2db1ff;
  background-color: transparent;
}

.cta__white.is--footer {
  margin-top: 20px;
  margin-left: 0px;
}

.cta__white.no--margin {
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
}

.cta__white.no--margin.color--primary {
  color: #2db1ff;
}

.reg__formwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 50%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.title__container {
  width: 100%;
}

.title__container.is__middke {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.reg__form_signuptext {
  position: relative;
  margin-top: 20px;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
}

.reg__form_signuptext.mt-0 {
  margin-top: 0px;
}

.reg__form_signuptext.is--shortlist {
  margin-top: 5px;
  color: #8e8e8e;
}

.week__textlink {
  color: #2db1ff;
  text-decoration: none;
}

.week__textlink.is--forgot {
  color: #8f8f8f;
  font-size: 1rem;
}

.registration__form {
  width: 100%;
  margin-top: 40px;
}

.registration__form-inner {
  width: 100%;
  margin-top: 0px;
}

.reg__formfield {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
}

.weekala__forminput {
  position: relative;
  height: 50px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
  border-radius: 5px;
}

.form__fieldwrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form__fieldwrapper.is--avatar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar__circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #969696;
}

.avatar__init {
  position: relative;
  color: #fff;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 400;
}

.week__about--titlecontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 46%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about__titlebg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 50%;
  background-color: #2caaf4;
}

.about__titlebg-image {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 50%;
  background-color: #5f9abd;
  background-image: url('../images/about__cover.png');
  background-position: 0px 0px;
  background-size: cover;
}

.week__para {
  position: relative;
  font-size: 1.2rem;
}

.week__para.is--middle {
  line-height: 1.6rem;
  text-align: center;
}

.week__para.is--middle.is--about {
  margin-bottom: 20px;
}

.week__para.is--middle.is--about.is--lg {
  color: #000;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}

.keyfeature__grid {
  width: 100%;
  max-width: 90%;
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 83px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.keyfeature__box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 40px 20px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.keyfeature_box--text {
  position: relative;
  z-index: 1;
  color: #2db1ff;
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.keyfeature__count {
  position: absolute;
  top: -21.8px;
  z-index: 0;
  opacity: 0.71;
  font-size: 6.8rem;
  line-height: 7rem;
  font-weight: 700;
}

.single__agency-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -o-object-fit: fill;
  object-fit: fill;
}

.sa-header--image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 180px;
  margin-right: 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 10px;
  background-color: #1554b3;
}

.sa-header--content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sa-header--bio {
  position: relative;
  font-size: 1.2rem;
  line-height: 1.5;
}

.sa-header--metawrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-h {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-h.is--pb-meta {
  margin-top: 10px;
  margin-right: 0px;
}

.flex-h.is--100 {
  width: 100%;
}

.flex-h.is--100.is--sb {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-h.is--100.is--sb.is--mb0 {
  margin-right: 0px;
}

.flex-h.is--100.is--sb.is--reviewbox {
  margin-right: 0px;
  margin-bottom: 30px;
}

.sa-header--metaicon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sa-header--metaicon.sa--ispb {
  width: 20px;
  height: 20px;
}

.sa-header--metatag {
  position: relative;
  color: #909090;
  font-size: 1rem;
  font-weight: 400;
}

.sa-header--metatag.is--small {
  font-size: 0.8rem;
}

.sa--grid {
  margin-top: 40px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 61px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sa--grid-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sa--block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
  border-radius: 10px;
  background-color: #fff;
}

.sa--block--titlewrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.sa--block--titlewrap.is--flexh {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sa--block-content {
  position: relative;
  width: 100%;
  padding: 30px;
}

.sa--block-content.is--pt0 {
  padding-top: 0px;
}

.sa--block-paragraph {
  position: relative;
  font-family: 'Biotif book', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
}

.sa--block-pillwrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sa--block-pillwrap.is--m0 {
  margin-top: 10px;
}

.sa--block-pills {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  border-radius: 100px;
  background-color: #f7f7f7;
  color: #454545;
  font-weight: 500;
  text-decoration: none;
}

.sa--block--portfolioblock {
  display: -ms-grid;
  display: grid;
  margin-bottom: 30px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sa--block--portfolioblock.mb--0 {
  margin-bottom: 0px;
}

.sa--block--portfolioimage {
  position: relative;
  width: 250px;
  height: 150px;
  margin-right: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.sa--block--portfoliocontent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sa--block--pb-title {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 600;
}

.sa--block--pb-titlewrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.viewproject__link {
  position: relative;
  color: #2db1ff;
  font-size: 1rem;
  text-decoration: none;
}

.sa-block-reviewwrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #efefef;
}

.sa-block-reviewwrap.mb--0 {
  margin-bottom: 0px;
}

.review__profilewrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.review__profileimage {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 50%;
}

.flex-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.review__profile-name {
  font-size: 1rem;
  font-weight: 500;
}

.review__profile-position {
  position: relative;
  margin-top: 5px;
  color: #909090;
  font-size: 0.9rem;
}

.review__profile-position.is--postedon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 0.7rem;
  font-style: italic;
}

.review__profile-review {
  position: relative;
  padding: 10px 18px;
  border-radius: 10px;
  background-color: #2db1ff;
  color: #fff;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 600;
}

.review__profile-review:hover {
  background-color: #242d3c;
}

.review-boxpara {
  position: relative;
  color: #676767;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.review__title {
  position: relative;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}

.review__recomededbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sa--rightcta-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.shortlist__agency {
  margin-top: 10px;
  color: #909090;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}

.sa--elsewhere__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sa__elsewhere-title {
  position: relative;
  margin-bottom: 10px;
  color: #909090;
  font-weight: 500;
}

.sa--elsewhere-linkd {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #909090;
}

.sa--elsewhere_linkblock {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.sa--elsewhere-linkicon {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.agency__header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 190px;
  padding-bottom: 190px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/design-agency.png');
  background-position: 50% 50%;
  background-size: cover;
}

.agency-listformwrap {
  position: absolute;
  bottom: -177px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  max-width: 1130px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0 hsla(0, 0%, 70.5%, 0.56);
}

.agency__searchform-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.agency__sf-filterwrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.agency__filterresult_link {
  margin-right: 20px;
  color: #2db1ff;
  font-weight: 500;
  text-decoration: underline;
}

.agency_searchform__botton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.is__agencyform {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.is__agencyform.is-30 {
  width: 30%;
}

.agency__sortby-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 3px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #b6b6b6;
}

.agency__result-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.agency__result-container.is--10 {
  width: 100%;
  margin-top: 0px;
}

.navigation__menuwrap {
  position: absolute;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.navgiation__menulink {
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  font-size: 1rem;
  text-decoration: none;
}

.learnmore__link {
  position: absolute;
  left: 50%;
  bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.span--highlight {
  position: relative;
  color: #2db1ff;
}

.blog__grid-container {
  margin-top: 60px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.blogsiderbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blog__catlink {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #0d0d0d;
  text-decoration: none;
}

.blog__catlink:hover {
  color: #2db1ff;
  font-weight: 500;
}

.blog__catlink.is--active {
  color: #2db1ff;
  font-weight: 500;
}

.bloglist--date {
  margin-top: 10px;
  color: #636363;
}

.navigation__logo {
  position: absolute;
  top: -77px;
  width: 140px;
}

.mobile__header {
  display: none;
}

.mobile__menu {
  display: none;
}

.con__header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 160px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/addagency1.png');
  background-position: 50% 50%;
  background-size: cover;
}

.cong--textwrap {
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.cong--text {
  position: relative;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
}

.explore--desc {
  position: relative;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
}

.addagency-form {
  max-width: 50%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
}

.dark-formlabel {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.addagency-forminput {
  position: relative;
  height: 50px;
  border-radius: 5px;
}

.inoutwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blog--titleheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.singlepost--blogimage {
  position: relative;
  max-width: 80%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  text-align-last: center;
}

.blogimg {
  position: relative;
  height: 400px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog--postscontainer {
  max-width: 60%;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  line-height: 1.6;
  font-size: 14pt;
}

.blog--postscontainer iframe {
  width: 100% !important;
}

.blog-text {
  font-size: 17px;
}

.shortlisted__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 70%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6eff3;
}

.navigation__avatar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation__avtar--inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navavatar__icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.navigation__dd {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 36;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300%;
  height: auto;
  padding: 9px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #c5c5c5;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #c1c1c1;
  -webkit-transform: translate(0px, 100%);
  -ms-transform: translate(0px, 100%);
  transform: translate(0px, 100%);
}

.shortlist__dd--link {
  position: relative;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000;
  font-size: 0.9rem;
  text-decoration: none;
}

.shortlist__dd--link.is--signout {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e9e9e9;
  color: #ec2323;
}

.agency__rating-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.agency__hover {
  position: absolute;
  left: 50%;
  bottom: -24px;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 15px 0px;
  background-color: #242d3c;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.41);
  -webkit-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.agency__rating-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  margin-top: 8px;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.agency__rating-row._w-star {
  width: 400px;
}

.agency__rating-title {
  position: relative;
  color: hsla(0, 0%, 100%, 0.89);
  font-weight: 500;
}

.agency__rating-title._w-stars {
  color: #000;
  font-size: 1.1rem;
  font-weight: 600;
}

.rating__conut {
  color: #fff;
  font-weight: 600;
}

.ratin__arrow {
  position: absolute;
  left: 50%;
  top: -11px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

@media screen and (max-width: 991px) {
  .week__container {
    position: relative;
    max-width: 95%;
  }

  .week__container.is--footer {
    max-width: 95%;
  }

  .hero__content {
    max-width: 95%;
  }

  .heading_h1.is--middle {
    font-size: 2.8rem;
  }

  .weekala__section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .weekala__section.pb-0 {
    padding-bottom: 35px;
  }

  .weekala__section.pb-0.about--hero {
    padding-top: 120px;
  }

  .heading_h2.is--blogheader {
    max-width: 80%;
  }

  .weekala__services {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .section__titlecontainer.is--left {
    max-width: 100%;
  }

  .featured__grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 41px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .featured__grid-box.is--1.is--agencyresult {
    width: 30%;
  }

  .blog__grid.is--bloglist {
    margin-top: 20px;
    grid-column-gap: 29px;
    grid-row-gap: 35px;
  }

  .navigaiton__header {
    display: none;
  }

  .navigation__inner {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .reg__formwrap {
    max-width: 80%;
  }

  .week__about--titlecontainer {
    width: 100%;
  }

  .about__titlebg {
    width: 100%;
  }

  .about__titlebg-image {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 300px;
    margin-top: 40px;
  }

  .sa--grid {
    grid-column-gap: 27px;
  }

  .navigation__menuwrap {
    display: none;
  }

  .blog__grid-container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .blogsiderbar {
    position: relative;
    overflow: auto;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile__header {
    position: fixed;
    top: 0px;
    z-index: 18;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 19px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }

  .mobile__logo {
    position: absolute;
    left: 20px;
    width: 100px;
  }

  .mobile__menu {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    height: 100vh;
    padding: 37px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
    -webkit-transform: translate(100%, 0px);
    -ms-transform: translate(100%, 0px);
    transform: translate(100%, 0px);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .mobile__menu.mobile__menu-active {
    position: fixed;
    top: 0px;
    z-index: 3;
    padding-top: 80px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .mobile__menu--block {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    color: #000c14;
    text-decoration: none;
  }

  .mobile__menu-block-title {
    position: relative;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .menu-block.mobile__navigation-btn {
    z-index: 7;
  }

  .addagency-form {
    position: relative;
    max-width: 80%;
  }

  .blog--titleheader {
    max-width: 80%;
  }

  .singlepost--blogimage {
    max-width: 95%;
  }

  .blog--postscontainer {
    max-width: 80%;
  }

  .shortlisted__box {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .week__container.container-small {
    max-width: 95%;
  }

  .weekala__section {
    padding-top: 51px;
    padding-bottom: 51px;
  }

  .weekala__section.pb-0.about--hero {
    padding-top: 100px;
  }

  .heading_h2.is--middle.max--width70 {
    max-width: 95%;
    font-size: 1.8rem;
    line-height: 2.1rem;
  }

  .heading_h2.is--sectiontitle.is--white {
    font-size: 2rem;
  }

  .heading_h2.mb-20 {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  .heading_h2.singlepost--title.is--white {
    font-size: 2rem;
  }

  .weekala__services {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .heading_h3.sa--block-title {
    font-size: 1.1rem;
    line-height: 2.2rem;
  }

  .section__titlecontainer {
    max-width: 85%;
  }

  .featured__grid-box.is--1.is--agencyresult {
    width: 48%;
  }

  .featured__grid-box.is--1.is--agencyresult.is--shortlisted {
    width: 48%;
  }

  .section__title-description.is--white {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .blog__grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .blog__grid-sidescroller {
    display: flex;
    overflow: auto;
  }

  .footer__grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .reg__formwrap {
    max-width: 90%;
  }

  .keyfeature__grid {
    grid-column-gap: 39px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .keyfeature__count {
    font-size: 4.8rem;
  }

  .single__agency-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sa-header--image {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }

  .sa-header--bio {
    font-size: 1rem;
    line-height: 1.5;
  }

  .sa-header--metaicon {
    position: relative;
    width: 25px;
    height: 25px;
  }

  .sa-header--metatag {
    font-size: 0.9rem;
  }

  .sa--grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sa--grid-col {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .sa--grid-col.is--first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .sa--grid-col.is--last {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .sa--block--titlewrap {
    padding: 10px 20px;
  }

  .sa--block-content {
    padding: 20px;
  }

  .agency__result-container {
    margin-top: 0px;
  }

  .blogsiderbar {
    border-bottom: 1px solid #d1d1d1;
    background-color: #f5f5f5;
  }

  .blog__catlink {
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .blog__catlink.is--active {
    margin-left: 0px;
  }

  .blogimg {
    height: 300px;
  }

  .agency__hover {
    left: auto;
    right: 0px;
    -webkit-transform: translate(0%, 100%);
    -ms-transform: translate(0%, 100%);
    transform: translate(0%, 100%);
  }

  .ratin__arrow {
    left: auto;
    right: 0px;
    -webkit-transform: translate(0%, 0px);
    -ms-transform: translate(0%, 0px);
    transform: translate(0%, 0px);
  }
}

@media screen and (max-width: 479px) {
  .week__container {
    max-width: 90%;
  }

  .week__container.is--footer {
    max-width: 90%;
  }

  .week__container.container-small {
    max-width: 95%;
  }

  .hero__content {
    max-width: 100%;
  }

  .heading_h1.is--middle {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  .hero__searchbox-container {
    padding-left: 0px;
  }

  .hero__searchform__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero__searchbox-submit {
    width: 100%;
    height: 55px;
  }

  .weekala__section.is--gray {
    padding-top: 80px !important;
  }

  .weekala__section.pb-0.about--hero {
    padding-top: 120px;
  }

  .heading_h2 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .heading_h2.is--sectiontitle {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .heading_h2.mb-20 {
    font-size: 1.6rem;
  }

  .heading_h2.singlepost--title {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .weekala__services {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .weekala__servicecard.is--digitalagency {
    height: 300px;
  }

  .heading_h3.is--black.is--blocktitle.is--shortlist {
    text-align: center;
  }

  .featured__grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .featured__grid-sidescroller {
    /* display: flex; */
    overflow: auto
  }

  .featured__grid-box.is--1 {
    margin-top: 0px;
    margin: auto
  }

  .featured__grid-box.is--1.is--agencyresult {
    width: 100%;
    margin-bottom: 30px;
  }

  .featured__grid-box.is--1.is--agencyresult.is--shortlisted {
    width: 100%;
  }

  .featured__grid-box.is--3 {
    margin-top: 0px;
  }

  .cta__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cta__primary {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .cta__primary.is--outline.is--primary.no-margin.submit-review {
    width: auto;
    margin-bottom: 0px;
    padding: 8px 10px;
    font-size: 0.8rem;
  }

  .cta__primary.is--outline.is--primary.is--full.mt-20.mt-0 {
    margin-top: 0px;
  }

  .howitworks__grid {
    max-width: 100%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  /* .blog__grid--image.is--bloglist {
    height: 380px;
  } */
  .navigation__inner {
    display: none;
  }

  .reg__formwrap {
    max-width: 100%;
  }

  .reg__form_signuptext {
    text-align: center;
  }

  .week__para.is--middle.is--about {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .sa-header--image {
    width: 100px;
    height: 100px;
  }

  .sa-header--metawrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-h {
    margin-bottom: 10px;
  }

  .flex-h.is--100.is--sb.is--reviewbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .sa-header--metaicon {
    border-radius: 50%;
  }

  .sa--block-paragraph {
    font-size: 0.9rem;
  }

  .sa--block-pills {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 7px 14px;
    font-size: 13px;
  }

  .sa--block--portfolioblock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .viewproject__link {
    margin-top: 20px;
    font-size: 0.9rem;
  }

  .review__profilewrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .review__profileimage {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  .review__profile-review {
    margin-top: 20px;
    padding-right: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
  }

  .review-boxpara {
    font-size: 0.9rem;
  }

  .review__title {
    font-size: 1rem;
    line-height: 1.4;
  }

  .sa--elsewhere-linkicon {
    border-radius: 50%;
  }

  .agency__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .agency-listformwrap {
    position: relative;
    bottom: 0px;
    padding: 10px;
    max-width: 300px;
    margin-top: 40px;
  }

  .is__agencyform.is-30 {
    width: 100%;
    margin-bottom: 20px;
  }

  .blog__grid-container {
    margin-top: 40px;
  }

  .mobile__logo {
    left: 10px;
  }

  .mobile__menu {
    width: 90%;
    padding: 28px;
  }

  .mobile__menu.mobile__menu-active {
    width: 100%;
  }

  .con__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cong--textwrap {
    max-width: 95%;
  }

  .addagency-form {
    max-width: 95%;
  }

  .blog--titleheader {
    max-width: 95%;
  }

  .agency__rating-wrap {
    position: absolute;
    right: 0px;
  }

  .agency__hover {
    left: auto;
    right: 0px;
  }

  .ratin__arrow {
    left: auto;
    right: 0px;
  }
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif book';
  src: url('../fonts/Biotif-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}