.cta__primary.is--header-button {
    padding: 16px 28px;
}

.sticky-2 {
    position: fixed !important;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 100;
    box-shadow: 1px 3px 20px #00000042 !important;
    padding: 15px 0;
}

.sticky-2 {
    background-color: white !important;
}

.menu-block.mobile__navigation-btn-inactive {
    z-index: 7;
}

.blog-date {
    font-size: 0.7rem;
    color: #746c6c;
}